import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import JobCard from '../parivartanHome/JobCard';
import axiosInstance from '../../services/AxiosInstance';
import './Career.css';

const Career = () => {
    const [jobData, setJobData] = useState([]); // State to store jobs fetched from API
    const [selectedJob, setSelectedJob] = useState(null);
    const [searchQuery, setSearchQuery] = useState(''); // State for search input
    const [loading, setLoading] = useState(true); // State to handle loading

    // Fetch job data from API on component mount
    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axiosInstance.get('/backend/jobs/getall'); 
                console.log(response);
                const sortedJobs = response.data.sort((a, b) => {
                    return b.id - a.id;  // Sort by `id` in descending order
                });
                setJobData(sortedJobs); 
            } catch (error) {
                console.error('Error fetching jobs:', error);
            } finally {
                setLoading(false); // Set loading to false when data is fetched
            }
        };

        fetchJobs();
    }, []);

    // Function to open the modal with the selected job data
    const openModal = (job) => {
        console.log('Opening modal for job:', job); // Debugging log
        setSelectedJob(job);
    };

    // Function to close the modal
    const closeModal = () => {
        setSelectedJob(null);
    };

    // Handler for updating the search query
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter jobs based on the search query (search by title, category, location, or company)
    const filteredJobs = jobData.filter((job) => {
        const searchLower = searchQuery.toLowerCase();
        return (
            job.title?.toLowerCase().includes(searchLower) ||
            job.category?.toLowerCase().includes(searchLower) ||
            job.location?.toLowerCase().includes(searchLower) ||
            job.company?.toLowerCase().includes(searchLower)
        );
    });

    return (
        <>
            <div className="mx-3 my-2 rounded-lg bg-gradient-to-r from-blue-700 to-green-700 text-white py-14 text-center mt-20 font-serif">
                <h1 className="text-2xl md:text-3xl font-extrabold tracking-tight uppercase mb-4">
                    Invest in yourself today for a brighter career tomorrow!
                </h1>
                <p className="text-base md:text-lg font-medium italic">
                    Find what you love and let it guide your career path. 🌟 #DreamJob
                </p>
            </div>

            {/* Job Opening Section */}
            <div className="bg-gray-100 py-12">
                <h2 className="text-2xl md:text-3xl font-semibold mb-8 text-center font-serif">
                    Current Job Openings
                </h2>

                {/* Search Input */}
                <div className="flex justify-center mb-8">
                    <input
                        type="text"
                        placeholder="Search by job title, category, location, or company"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="w-full max-w-md px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                </div>

                {/* Loading State */}
                {loading ? (
                    <div className="flex justify-center items-center">
                    <div className="spinner mr-2"></div>
                    <p className="text-gray-600">Loading New Jobs for you...</p>
                </div>
                ) : (
                    // Render filtered jobs
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 px-4 md:px-8 lg:px-16 justify-center mx-auto">
                        {filteredJobs.length > 0 ? (
                            filteredJobs.map((job) => (
                                <JobCard key={job.id} job={job} openModal={openModal} />
                            ))
                        ) : (
                            <p className="text-center col-span-full text-gray-500">No jobs found matching your search criteria.</p>
                        )}
                    </div>
                )}
            </div>

            {selectedJob && (
    <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-scroll top-24  z-20 ">
        <div className="bg-white rounded-lg p-4 w-full  sm:p-8 max-w-full sm:max-w-2xl mx-auto absolute  top-10  ">
            <div className="flex justify-between items-center mb-4 mt-10">
                <h3 className="text-2xl font-bold ">{selectedJob.title}</h3>
                <button onClick={closeModal} className="text-2xl text-rose-600">
                    &times;
                </button>
            </div>
            <hr className="border-t border-blue-500 my-4" />

            {/* Profile Required */}
            {Array.isArray(selectedJob.profile) ? (
                <div className="mb-4">
                    <strong>Profile Required:</strong>
                    <ul className="list-disc pl-5 mt-2">
                        {selectedJob.profile.map((profile, index) => (
                            <li key={index} className="text-left mb-2">{profile}</li>
                        ))}
                    </ul>
                </div>
            ) : (
                <div className="mb-4">
                    <strong>Profile Required:</strong>
                    <p className="text-left mb-2">{selectedJob.profile}</p>
                </div>
            )}

            {/* Skills Required */}
            {selectedJob.skills && (
                <div className="mb-4">
                    <strong>Skills Required:</strong>
                    <ul className="list-disc pl-5 mt-2">
                        {selectedJob.skills.map((skill, index) => (
                            <li key={index} className="text-left mb-2">{skill}</li>
                        ))}
                    </ul>
                </div>
            )}

                        <hr className="border-t border-blue-500 my-4" />

                        {/* Apply Button */}
                        <div className="flex justify-center">
                                <Link
                                    to={`/apply/${encodeURIComponent(selectedJob.category)}/${encodeURIComponent(selectedJob.title)}`}
                                    className="bg-first-500 text-white py-2 px-4 rounded-lg hover:bg-first-700 transition duration-300 ease-in-out"
                                >
                                    Apply
                                </Link>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Career;
