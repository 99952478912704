import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../services/AxiosInstance';
import {  FaTrash } from 'react-icons/fa';
import Swal from 'sweetalert2';


// Main JobDetailsPage Component
const JobDetailsPage = () => {
  const {jobCategory} = useParams();
  const [decodedJobCategory, setJobCategory] = useState('');
  const [candidates, setCandidates] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);


  // Fetch candidates data
  const fetchCandidates = useCallback(async () => {
    try {
      if(jobCategory){
        const decoded = decodeURIComponent(jobCategory);
        setJobCategory(decoded);
        console.log(decoded);
      }
      const response = await axiosInstance.get(`/backend/career/job/${decodedJobCategory}`);
      console.log(response);
      setCandidates(response.data);
      setError('');
    } catch (err) {
      setError('Failed to fetch candidates. Please try again later.');
      console.error('Error fetching candidates:', err);
    }
  }, [jobCategory,decodedJobCategory]);

  useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  // Filter candidates based on search term
  useEffect(() => {
    
    setFilteredData(
      candidates.filter((candidate) =>
        [candidate.name, candidate.email, candidate.mobile, candidate.skills]
          .some((field) => field?.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    );
  }, [searchTerm, candidates]);

  // Handle candidate deletion
  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
  
      if (result.isConfirmed) {
        await axiosInstance.delete(`/backend/career/${id}`);
        setCandidates(candidates.filter(candidate => candidate.id !== id));
        Swal.fire(
          'Deleted!',
          'The candidate has been deleted.',
          'success'
        );
      }
    } catch (error) {
      console.error("Error deleting candidate:", error);
      Swal.fire(
        'Error!',
        'An error occurred while deleting the candidate.',
        'error'
      );
    }
  };

  useEffect(() => {
    document.title = `Job Details - ${jobCategory || 'All Jobs'}`;
  }, [jobCategory]);

  return (
    <div className="flex flext-col px-2 py-8 rounded-lg overflow-hidden bg-white items-start">
      <div className="p-8">
        <h1 className="text-3xl font-bold mb-6 text-left">Candidate List</h1>

        {/* Error Message */}
        {error && (
          <div className="text-red-500 text-center my-4">
            {error}
          </div>
        )}

            {/* Search Bar */}
            <form className="mb-4 bg-white flex items-center">
              <div className="relative flex-grow max-w-md">
                <input
                  type="search"
                  className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg"
                  placeholder="Search by Name, Email, Phone, or Skill..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  required
                />
              </div>
            </form>


        {/* Table container with scroll */}
        <div className="overflow-x-auto rounded-lg  overflow-y-auto">
          <table className="bg-white w-full">
            <thead>
              <tr>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Actions</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Name</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Email</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Phone</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Experience</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Category</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Job Title</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Skills</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Applied On</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">total mcq</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Correct Answers</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">MCQ percentage</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Resume</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Aadhar Card</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">10th Certificate</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">12th/Diploma Certificate</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Degree</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Internship Letter</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Experience Letter</th>
                <th className="py-3 px-4 bg-gray-800 text-white font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredData.length ? (
                filteredData.map((candidate, index) => (
                  <tr key={candidate.id}>
                    <td className="px-6 py-4 text-gray-900">{index + 1}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.name}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.email}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.mobile}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.experience}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.jobCategory}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.jobTitle}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.skills}</td>
                    <td className="px-6 py-4 text-gray-500">{candidate.appliedon}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {candidate.mcqResult?.totalMcqQuestions !== undefined ? candidate.mcqResult.totalMcqQuestions : 'Not Available'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {candidate.mcqResult?.correctMcqAnswers !== undefined ? candidate.mcqResult.correctMcqAnswers : 'Not Available'}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {candidate.mcqResult?.percentageMcqAnswers !== undefined ? candidate.mcqResult.percentageMcqAnswers : 'Not Available'}
                    </td>
                    <td className="px-6 py-4">
                    {candidate?.resume ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate?.resume}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                    </td>
                    <td className="px-6 py-4">
                    {candidate.document?.aadhar ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.aadhar}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap ">
                                    {candidate.document?.sscCertificate ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.sscCertificate}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap ">
                                    {candidate.document?.hscDiplomaCertificate ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.hscDiplomaCertificate}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center "
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap ">
                                    {candidate.document?.degree ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.degree}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap ">
                                    {candidate.document?.internship ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.internship}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center  justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap  ">
                                    {candidate.document?.experience ? (
                                        <a
                                            href={`${axiosInstance.defaults.baseURL}/backend/document/${candidate.document?.experience}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center  justify-center"
                                        >
                                            <i className="fa-solid fa-eye" />
                                        </a>
                                    ) : (
                                        'No document'
                                    )}
                                </td>
                    <td className="px-6 py-4">
                      <button onClick={() => handleDelete(candidate.id)} className="text-white ml-2 p-1 px-4 rounded-xl">
                       <FaTrash size={24} color='red'/> 
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="15" className="text-center py-4 text-gray-500 italic">
                    No candidates found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsPage;
