import React from 'react';
import Hero from './Hero';
import Services from './Services';
// import Contact from './Contact';
import Feedback from './Feedback';
import Navbar from './NavBar';
import JobSliderNotification from './JobSliderNotification';
import JobList from './JobList';
import ReadyToTransform from './ReadyToTransform';

export default function Home() {


  return (
    <div>

      <Navbar />

      <Hero />
      <JobSliderNotification />

      <JobList />

      <Services />

      <Feedback />

      <ReadyToTransform />
     
    </div>
  );
}
