import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import CustomSwitch from '../CustomSwitch';
import Swal from 'sweetalert2';
import { Trash2 } from 'lucide-react';

export default function ContactTable() {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    fetchContacts();
  }, []);

  const fetchContacts = async () => {
    try {
      const response = await axiosInstance.get('/backend/contact');
      setContacts(response.data);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    }
  };

  const toggleContacted = async (id, contacted) => {
    if (contacted) return; // Prevent toggling if already contacted

    try {
      await axiosInstance.put(`/backend/contact/${id}/toggle-contacted`);
      fetchContacts();
    } catch (error) {
      console.error('Error toggling contacted status:', error);
    }
  };

  const deleteContact = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to delete this contact? This action cannot be undone.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Delete',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/backend/contact/${id}`);
        Swal.fire({
          icon: 'success',
          title: 'Deleted',
          text: 'The contact has been successfully deleted.',
        });
        fetchContacts();
      } catch (error) {
        console.error('Error deleting contact:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to delete the contact.',
        });
      }
    }
  };

  return (
    <div className="container mx-auto p-8">
      <h2 className="text-2xl font-bold mb-4">Contacts</h2>
      <table className="w-full table-auto border">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">ID</th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Mobile Number</th>
            <th className="px-4 py-2">Email</th>
            <th className="px-4 py-2">Message</th>
            <th className="px-4 py-2">Contacted</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((c) => (
            <tr key={c.id}>
              <td className="border px-4 py-2">{c.id}</td>
              <td className="border px-4 py-2">{c.name}</td>
              <td className="border px-4 py-2">{c.mobilenumber}</td>
              <td className="border px-4 py-2">{c.email}</td>
              <td className="border px-4 py-2">{c.message}</td>
              <td className="border px-4 py-2 text-center">
                <CustomSwitch
                  checked={c.contacted}
                  onChange={() => toggleContacted(c.id, c.contacted)}
                  disabled={c.contacted} // Disable switch if already contacted
                />
              </td>
              <td className="border px-4 py-2 text-center">
                <button
                    onClick={() => deleteContact(c.id)}
                    className="px-2 py-2  text-white rounded transition"
                    title="Delete Contact"
                >
                    <Trash2 size={20} color='red'/>
                </button>
                </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
