import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight, Briefcase, Users, BookOpen, BarChartIcon as ChartBar, Award, Headphones } from 'lucide-react';

const services = [
  {
    title: "Job Placement",
    description: "We connect qualified candidates with top employers for permanent roles across various industries, ensuring the perfect match for both parties.",
    icon: <Briefcase className="w-12 h-12 text-blue-500" />,
    image: "./images/jobplacement.png?height=300&width=400"
  },
  {
    title: "Staffing Solutions",
    description: "Our efficient staffing services help businesses find temporary, contract, and permanent employees to meet their dynamic workforce needs.",
    icon: <Users className="w-12 h-12 text-green-500" />,
    image: "./images/staffingsolutions.png?height=300&width=400"
  },
  {
    title: "Career Guidance",
    description: "Expert career counseling and resume optimization services to help job seekers stand out in competitive markets and achieve their career goals.",
    icon: <BookOpen className="w-12 h-12 text-purple-500" />,
    image: "./images/careerguidance.png?height=300&width=400"
  },
  {
    title: "Skills Assessment",
    description: "Comprehensive evaluation of candidates' skills to ensure they meet industry standards and employer requirements.",
    icon: <ChartBar className="w-12 h-12 text-red-500" />,
    image: "./images/skillsassessment.png?height=300&width=400"
  },
  {
    title: "Professional Development",
    description: "Tailored training programs to enhance employees' skills and keep them updated with the latest industry trends.",
    icon: <Award className="w-12 h-12 text-yellow-500" />,
    image: "./images/profdevelopement.png?height=300&width=400"
  },
  {
    title: "HR Consulting",
    description: "Strategic HR solutions to help businesses optimize their human resources processes and improve overall efficiency.",
    icon: <Headphones className="w-12 h-12 text-indigo-500" />,
    image: "./images/hrconsulting.png?height=300&width=400"
  }
];

export default function Services() {
  return (
    <section id="services" className="py-20 bg-gradient-to-b from-gray-50 to-white font-serif">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold text-gray-800 mb-4 font-yearbook">Our Services</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto font-yearbook">
            Empowering careers and businesses with our comprehensive range of professional services.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 border-2 border-gray-300">
              <div className="relative">
                <img src={service.image} alt={service.title} className="w-full h-48 object-cover" />
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center opacity-0 transition-opacity duration-300 hover:opacity-100">
                  <p className="text-white text-center px-4 font-yearbook">{service.description}</p>
                </div>
              </div>
              <div className="p-6">
                <div className="flex items-center mb-4">
                  {service.icon}
                  <h3 className="text-2xl font-semibold ml-4 font-serif">{service.title}</h3>
                </div>
                <Link to="/services">
                  <span className="mt-4 flex items-center text-blue-600 hover:text-blue-800 transition duration-300 font-yearbook cursor-pointer">
                    Learn More <ArrowRight className="w-4 h-4 ml-2" />
                  </span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

