import React, { useState, useEffect } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import { useNavigate } from "react-router-dom/dist";
import { isLoggedIn } from "../../../auth";
import { FiTrash, FiEdit } from "react-icons/fi";

const ShowMcq = () => {
  const navigate = useNavigate();

  const [mcqs, setMcqs] = useState([]);
  const [jobTitle, setJobTitle] = useState("all"); 
  const [jobTitles, setJobTitles] = useState([]); 
  const [jobTitlesValues, setJobTitlesValues] = useState([]); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedMcqId, setSelectedMcqId] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  // Redirect to login if not logged in
  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  // Fetch job titles on component mount
  useEffect(() => {
    const fetchJobTitles = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("/backend/jobs/gettitles");
        console.log(response);
        setJobTitles(response.data.dtitles || []); 
        setJobTitlesValues(response.data.titles || [])

      } catch (err) {
        setError("Failed to load job titles");
      } finally {
        setLoading(false);
      }
    };

    fetchJobTitles();
  }, []);

  // Fetch MCQs based on selected job title
  useEffect(() => {
    const fetchMcqs = async () => {
      setLoading(true);
      setError(null);
      setSuccessMessage("");
      try {
        const url =
          jobTitle === "all"
            ? "/backend/mcq/question"
            : `/backend/mcq/job/${jobTitle}`;
        const response = await axiosInstance.get(url);
        setMcqs(response.data || []);
      } catch (err) {
        setError("Failed to fetch MCQs.");
      } finally {
        setLoading(false);
      }
    };

    fetchMcqs();
  }, [jobTitle]);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await axiosInstance.delete(
        `/backend/mcq/question/delete/${selectedMcqId}`
      );
      setMcqs((prev) => prev.filter((mcq) => mcq.id !== selectedMcqId));
      setSuccessMessage("MCQ deleted successfully.");
      closeModal();
    } catch (err) {
      setError("Failed to delete MCQ.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (id) => {
    navigate("/admin/update-mcq", { state: { id } });
  };

  const handleJobTitleChange = (e) => {
    setJobTitle(e.target.value);
  };

  const openModal = (id) => {
    setSelectedMcqId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedMcqId(null);
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">MCQ Table</h2>

      {/* Filter by Job Title */}
      <div className="mb-4">
        <label className="block text-gray-700 font-semibold mb-2">
          Filter by Job Title
        </label>
        <select
          value={jobTitle}
          onChange={handleJobTitleChange}
          className="w-full p-2 border border-gray-300 rounded-lg"
        >
          <option value="all">All</option>
          {jobTitles.length > 0 ? (
            jobTitles.map((job, index) => (
            <option key={index} value={jobTitlesValues[index]}>
                {job}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No job titles available
            </option>
          )}
        </select>
      </div>

      {/* Loading, Error, Success Messages */}
      {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>}
      {successMessage && <p className="text-green-500">{successMessage}</p>}

      {/* MCQ Table */}
      {!loading && mcqs.length > 0 && (
        <div
          className="overflow-auto rounded-lg"
          style={{ maxHeight: "500px" }}
        >
          <table className="min-w-full bg-white border border-gray-300 shadow-md">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">#</th>
                <th className="px-4 py-2 border-b">Question</th>
                <th className="px-4 py-2 border-b">Option A</th>
                <th className="px-4 py-2 border-b">Option B</th>
                <th className="px-4 py-2 border-b">Option C</th>
                <th className="px-4 py-2 border-b">Option D</th>
                <th className="px-4 py-2 border-b">Correct Answer</th>
                <th className="px-4 py-2 border-b">Job Title</th>
                <th className="px-4 py-2 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {mcqs.map((mcq, index) => (
                <tr key={mcq.id}>
                  <td className="px-4 py-2 border-b">{index + 1}</td>
                  <td className="px-4 py-2 border-b">
                    <pre className="w-96 whitespace-pre-wrap break-words">
                      {mcq.question}
                    </pre>
                  </td>
                  <td className="px-4 py-2 border-b">{mcq.optionA}</td>
                  <td className="px-4 py-2 border-b">{mcq.optionB}</td>
                  <td className="px-4 py-2 border-b">{mcq.optionC}</td>
                  <td className="px-4 py-2 border-b">{mcq.optionD}</td>
                  <td className="px-4 py-2 border-b">{mcq.correctAnswer}</td>
                  <td className="px-4 py-2 border-b">{mcq.jobTitle}</td>
                  <td className="px-4 py-2 border-b">
                    <div className="flex justify-center items-center">
                      <button
                        onClick={() => handleUpdate(mcq.id)}
                        className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                      >
                        <FiEdit />
                      </button>
                      <button
                        onClick={() => openModal(mcq.id)}
                        className="bg-red-500 text-white px-2 py-1 rounded"
                      >
                        <FiTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!loading && !error && mcqs.length === 0 && <p>No MCQs found.</p>}

      {/* Delete Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h3 className="text-lg font-bold mb-4">Confirm Deletion</h3>
            <p>Are you sure you want to delete this MCQ?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={closeModal}
                className="bg-green-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShowMcq;
