import * as Switch from '@radix-ui/react-switch';
import React from 'react';

export default function CustomSwitch({ checked, onChange, disabled }) {
  return (
    <Switch.Root
      className={`relative w-12 h-6 rounded-full outline-none transition-colors ${
        checked
          ? 'bg-green-700' // Green background when checked
          : 'bg-red-700' // Red background when not checked
      }`}
      checked={checked}
      onCheckedChange={onChange}
      disabled={disabled}
    >
      <Switch.Thumb
        className={`block w-5 h-5 bg-white rounded-full shadow transition-transform duration-200 ${
          checked ? 'translate-x-6' : 'translate-x-0' // Move dot right when checked
        }`}
      />
    </Switch.Root>
  );
}
