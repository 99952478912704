import React, { useState, useEffect } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import Swal from 'sweetalert2';

const initialFormData = {
  title: '',
  dtitle: '',
  company: '',
  category: '',
  location: '',
  profile: [],
  skills: [],
  age: '',
  vacancies: 0,
  experience: '',
  gender: '',
};

export default function JobForm({ job, onUpdateSuccess }) {
  const [formData, setFormData] = useState(job || initialFormData);
  const isEditing = !!job;

  useEffect(() => {
    if (job) {
      setFormData(job);
    }
  }, [job]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleArrayInputChange = (e, field) => {
    const values = e.target.value.split('\n').filter(item => item.trim() !== '');
    setFormData(prevState => ({
      ...prevState,
      [field]: values
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const cleanTitle = formData.dtitle.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

    const cleanedFormData = {
      ...formData,
      title: cleanTitle, 
    };

    try {
      let response;
      if (isEditing) {
        response = await axiosInstance.put(`/backend/jobs/${job.id}`, cleanedFormData);
      } else {
        response = await axiosInstance.post('/backend/jobs/createjob', cleanedFormData);
      }
      
      if (response.data) {
        await Swal.fire(
          'Success!',
          `Job ${isEditing ? 'updated' : 'added'} successfully!`,
          'success'
        );
        if (isEditing && onUpdateSuccess) {
          onUpdateSuccess();
        } else {
          setFormData(initialFormData);
        }
      } else {
        throw new Error('Failed to process job data');
      }
    } catch (error) {
      console.error(`Error ${isEditing ? 'updating' : 'adding'} job:`, error);
      await Swal.fire(
        'Error!',
        `An error occurred while ${isEditing ? 'updating' : 'adding'} the job. Please try again.`,
        'error'
      );
    }
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-2xl rounded-lg px-8 pt-6 pb-8 mb-4 max-w-2xl mx-auto">
      <h2 className="text-3xl font-bold text-center mb-8 text-indigo-700">
        {isEditing ? 'Edit Job' : 'Add New Job'}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <label htmlFor="dTitle" className="block text-sm font-semibold text-gray-700 mb-1">Job Title</label>
          <input
            type="text"
            id="dtitle"
            name="dtitle"
            value={formData.dtitle}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
        </div>

        <div>
          <label htmlFor="company" className="block text-sm font-semibold text-gray-700 mb-1">Company</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
        </div>
        <div>
          <label htmlFor="category" className="block text-sm font-semibold text-gray-700 mb-1">Category</label>
          <input
            type="text"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
        </div>
        <div>
          <label htmlFor="location" className="block text-sm font-semibold text-gray-700 mb-1">Location</label>
          <input
            type="text"
            id="location"
            name="location"
            value={formData.location}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            required
          />
        </div>
        <div>
          <label htmlFor="age" className="block text-sm font-semibold text-gray-700 mb-1">Age Range</label>
          <input
            type="text"
            id="age"
            name="age"
            value={formData.age}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="vacancies" className="block text-sm font-semibold text-gray-700 mb-1">Number of Vacancies</label>
          <input
            type="number"
            id="vacancies"
            name="vacancies"
            value={formData.vacancies}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div>
          <label htmlFor="experience" className="block text-sm font-semibold text-gray-700 mb-1">Experience Required</label>
          <input
            type="text"
            id="experience"
            name="experience"
            value={formData.experience}
            onChange={handleInputChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          />
        </div>
      </div>
      <div className="mt-6">
        <label htmlFor="profile" className="block text-sm font-semibold text-gray-700 mb-1">Profile</label>
        <textarea
          id="profile"
          name="profile"
          value={formData.profile.join('\n')}
          onChange={(e) => handleArrayInputChange(e, 'profile')}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          rows="3"
          placeholder="Enter profile details (one per line)"
          required
        />
      </div>
      <div className="mt-6">
        <label htmlFor="skills" className="block text-sm font-semibold text-gray-700 mb-1">Skills</label>
        <textarea
          id="skills"
          name="skills"
          value={formData.skills.join('\n')}
          onChange={(e) => handleArrayInputChange(e, 'skills')}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
          rows="3"
          placeholder="Enter skills (one per line)"
          required
        />
      </div>
      <div className="mt-6">
        <label htmlFor="gender" className="block text-sm font-semibold text-gray-700 mb-1">Gender</label>
        <select
          id="gender"
          name="gender"
          value={formData.gender}
          onChange={handleInputChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <option value="">Select gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="both">Both Male and Female</option>
        </select>
      </div>
      <div className="mt-8">
        <button
          type="submit"
          className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
        >
          {isEditing ? 'Update Job' : 'Add Job'}
        </button>
      </div>
    </form>
  );
}

