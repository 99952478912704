import React, { useEffect, useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import CustomSwitch from '../CustomSwitch';
import Swal from 'sweetalert2';
import { Trash2 } from 'lucide-react';

export default function AskQuestionTable() {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axiosInstance.get('/backend/askquestion/getallaskedque');
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const toggleContacted = async (id, contacted) => {
    if (contacted) return;

    try {
      await axiosInstance.put(`/backend/askquestion/${id}/toggle-contacted`);
      Swal.fire({
        title: 'Success!',
        text: 'Contacted status updated successfully.',
        icon: 'success',
        timer: 1500,
      });
      fetchQuestions();
    } catch (error) {
      console.error('Error toggling contacted status:', error);
      Swal.fire('Error!', 'Failed to update contacted status.', 'error');
    }
  };

  const deleteQuestion = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won’t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axiosInstance.delete(`/backend/askquestion/${id}`);
          Swal.fire('Deleted!', 'The question has been deleted.', 'success');
          fetchQuestions();
        } catch (error) {
          console.error('Error deleting question:', error);
          Swal.fire('Error!', 'Failed to delete the question.', 'error');
        }
      }
    });
  };

  return (
    <div className="container mx-auto m-4 p-8">
      <h2 className="text-2xl font-bold mb-4">Asked Questions</h2>
      <table className="w-full table-auto border">
        <thead>
          <tr className="bg-gray-200">
            <th className="px-4 py-2">ID</th>
            <th className="px-4 py-2">Mobile Number</th>
            <th className="px-4 py-2">Message</th>
            <th className="px-4 py-2">Contacted</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {questions.map((q) => (
            <tr key={q.id}>
              <td className="border px-4 py-2 text-center">{q.id}</td>
              <td className="border px-4 py-2 text-center">{q.mobilenumber}</td>
              <td className="border px-4 py-2 text-center">{q.message}</td>
              <td className="border px-4 py-2 text-center">
                <CustomSwitch
                    checked={q.contacted}
                    onChange={() => toggleContacted(q.id, q.contacted)}
                     disabled={q.contacted} // Disable switch if already contacted
                                />
              </td>
              <td className="border px-4 py-2 text-center">
                <button
                  onClick={() => deleteQuestion(q.id)}
                  className="px-2 py-2  text-white rounded  transition"
                  title="Delete Question"
                >
                  <Trash2 size={24} color='red'/>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
