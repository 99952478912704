import React, { useState, useEffect } from 'react';

export default function FeedbackSection() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const testimonials = [
    {
      quote: "ConsultancyCo helped us find the right candidates quickly and efficiently. Their attention to detail and industry knowledge is second to none.",
      name: "Alena",
      position: "CEO of Corp",
      image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80',
      background: './images/feedback-bg.jpg'
    },
    {
      quote: "The career guidance and resume tips I received helped me land my dream job in no time. I highly recommend their services!",
      name: "Sarah Capital",
      position: "Marketing Manager",
      image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80',
      background: './images/feedback-bg.jpg'    },
    {
      quote: "Their personalized approach to career counseling opened up new opportunities I hadn't even considered. Truly life-changing!",
      name: "Alex Johnson",
      position: "Software Engineer",
      image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80',
      background: './images/feedback-bg.jpg'    },
    {
      quote: "The team's expertise in matching candidates with the right cultural fit has significantly improved our retention rates.",
      name: "Emily Chen",
      position: "HR Director",
      image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=256&q=80',
      background: './images/feedback-bg.jpg'    }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [testimonials.length]);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white py-12 font-serif">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h1 className="text-5xl font-bold text-gray-800 mb-4 font-yearbook">
            Success Stories
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto italic">
            Discover how we've helped professionals and businesses achieve their goals and transform their careers.
          </p>
        </div>

        <div className="relative">
          <div className="overflow-hidden">
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            >
              {testimonials.map((testimonial, index) => (
                <div key={index} className="w-full flex-shrink-0 px-4">
                  <div 
                    className="backdrop-blur-lg bg-black relative  rounded-xl shadow-2xl overflow-hidden transform transition duration-300 hover:scale-105 h-52"
                    style={{
                      backgroundImage: `url(${testimonial.background})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  >
                    <div className="absolute inset-0 bg-black opacity-50"></div>
                    <div className="relative z-10 p-8 h-full flex flex-col justify-between">
                      <div>
                        <div className="flex items-center mb-6">
                          <img 
                            src={testimonial.image} 
                            alt={testimonial.name} 
                            className="w-20 h-20 rounded-full object-cover border-4 border-blue-500 mr-4 shadow-lg"
                          />
                          <div>
                            <h3 className="text-2xl font-bold text-white font-yearbook">{testimonial.name}</h3>
                            <p className="text-blue-300 font-yearbook">{testimonial.position}</p>
                          </div>
                        </div>
                        <p className="text-white italic mb-4 font-yearbook text-xl leading-relaxed">
                          "{testimonial.quote}"
                        </p>
                      </div>
                      <div className="flex justify-end">
                        <svg className="w-12 h-12 text-blue-300 opacity-50" fill="currentColor" viewBox="0 0 32 32">
                          <path d="M10 8v8H6c0-4.4 3.6-8 8-8h2v2h-2c-3.3 0-6 2.7-6 6v6h8V8h-6zm14 0v8h-4c0-4.4 3.6-8 8-8h2v2h-2c-3.3 0-6 2.7-6 6v6h8V8h-6z"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button
            className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none"
            onClick={prevSlide}
          >
            <svg className="w-6 h-6 text-gray-800" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M15 19l-7-7 7-7"></path>
            </svg>
          </button>
          <button
            className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none"
            onClick={nextSlide}
          >
            <svg className="w-6 h-6 text-gray-800" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M9 5l7 7-7 7"></path>
            </svg>
          </button>
        </div>

        <div className="flex justify-center mt-4">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`w-3 h-3 rounded-full mx-1 focus:outline-none ${
                index === currentSlide ? 'bg-blue-500' : 'bg-gray-300'
              }`}
              onClick={() => setCurrentSlide(index)}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
}

