import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import "./App.css";

import PrivateRoute from "./components/parivartanHome/PrivateRoute";
import Sidebar from "./components/parivartanHome/Sidebar";
import NavBar from "./components/parivartanHome/NavBar";
import Footer from "./components/parivartanHome/Footer";
import ScrollToTop from "./components/parivartanHome/ScrollToTop";
import AskQuestion from "./components/parivartanHome/AskQuestion";

// Import all page components
import HomePage from "./components/parivartanHome/Home";
import CareerPage from "./pages/CareerPage";
import ApplyNowPage from "./pages/ApplyNowPage";
import StartTestpage from "./pages/StartTestpage";

import McqTestQuestion from "./components/mcqTestQuestion/McqTestQuestion";
import CodingTestInstructionPage from "./pages/CodingTestInstructionPage";
import CodingQuestionPage from "./pages/CodingQuestionPage";
import UploadDocumentPage from "./pages/UploadDocumentPage";
import AddMcqPage from "./pages/AddMcqPage";
import AddCodingPage from "./pages/AddCodingPage";
import ShowMcqPage from "./pages/ShowMcqPage";
import ShowCodingQuestionPage from "./pages/ShowCodingQuestionPage";
import UpdateMcqPage from "./pages/UpdateMcqPage";
import UpdateCodingQuestionPage from "./pages/UpdateCodingQuestionPage";
import ServicesPage from "./pages/ServicesPage";
import AboutUs from "./components/parivartanHome/AboutUs";

import LoginPage from "./components/parivartanHome/LoginPage";
import AllCandidates from "./components/parivartanHome/AllCandidatesList";
import Dashboard from "./components/parivartanHome/DashboardCategoryCard";
import JobDetailsPage from "./components/parivartanHome/CandidateList";
import Contact from "./components/parivartanHome/Contact";
import AddJob from "./components/admin/JobForm";

import ContactDetails from "./components/admin/ContactDetailsTable";
import AskQuestionDetails from "./components/admin/AskQuestionDetailsTbl";
import LoanPage from './pages/LoanPage';
import LoanDetailsTable from './components/admin/LoanDetailsTable';

const Layout = ({ children }) => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  return (
    <>
      {!isAdminRoute && <NavBar />}
      <div className={isAdminRoute ? "admin-layout" : ""}>
        {isAdminRoute && <Sidebar />}
        <main className={isAdminRoute ? "admin-main-content" : ""}>
          {children}
        </main>
      </div>
      {!isAdminRoute && <AskQuestion />}
      {!isAdminRoute && <Footer />}
    </>
  );
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />

      <Routes>
        {/* Public routes */}
        <Route path="/" element={<Layout><HomePage /></Layout>} />
        <Route path="/career" element={<Layout><CareerPage /></Layout>} />
        <Route path="/apply/:jobCategory/:jobTitle" element={<Layout><ApplyNowPage /></Layout>} />
        <Route path="/start-test" element={<Layout><StartTestpage /></Layout>} />
        {/* <Route path="/Instructions" element={<Layout><McqTestPage /></Layout>} /> */}
        <Route path="/mcq-test" element={<Layout><McqTestQuestion /></Layout>} />
        <Route path="/coding-instruction" element={<Layout><CodingTestInstructionPage /></Layout>} />
        <Route path="/coding-test" element={<Layout><CodingQuestionPage /></Layout>} />
        <Route path="/upload-document" element={<Layout><UploadDocumentPage /></Layout>} />
        <Route path="/login" element={<Layout><LoginPage /></Layout>} />
        <Route path="/services" element={<Layout><ServicesPage /></Layout>} />
        <Route path="/about" element={<Layout><AboutUs /></Layout>} />
        <Route path="/contact" element={<Layout><Contact /></Layout>} />
        <Route path="/loan" element={<Layout><LoanPage /></Layout>} />

        {/* Admin routes */}
        <Route path="/admin" element={<PrivateRoute><Layout><Dashboard /></Layout></PrivateRoute>} />
        <Route path="/admin/allcandidates" element={<PrivateRoute><Layout><AllCandidates /></Layout></PrivateRoute>} />
        <Route path="/admin/dashboard" element={<PrivateRoute><Layout><Dashboard /></Layout></PrivateRoute>} />
        <Route path="/admin/add_mcq" element={<PrivateRoute><Layout><AddMcqPage /></Layout></PrivateRoute>} />
        <Route path="/admin/job/:jobCategory" element={<PrivateRoute><Layout><JobDetailsPage /></Layout></PrivateRoute>} />
        <Route path="/admin/add-coding" element={<PrivateRoute><Layout><AddCodingPage /></Layout></PrivateRoute>} />
        <Route path="/admin/show-mcq" element={<PrivateRoute><Layout><ShowMcqPage /></Layout></PrivateRoute>} />
        <Route path="/admin/show-coding-question" element={<PrivateRoute><Layout><ShowCodingQuestionPage /></Layout></PrivateRoute>} />
        <Route path="/admin/update-mcq" element={<PrivateRoute><Layout><UpdateMcqPage /></Layout></PrivateRoute>} />
        <Route path="/admin/update-coding-question" element={<PrivateRoute><Layout><UpdateCodingQuestionPage /></Layout></PrivateRoute>} />
        <Route path="/admin/add_job" element={<PrivateRoute><Layout><AddJob /></Layout></PrivateRoute>} />
        <Route path="/admin/contactdetails" element={<PrivateRoute><Layout><ContactDetails /></Layout></PrivateRoute>} />
        <Route path="/admin/askquedetails" element={<PrivateRoute><Layout><AskQuestionDetails /></Layout></PrivateRoute>} />
        <Route path="/admin/loanapplications" element={<PrivateRoute><Layout><LoanDetailsTable /></Layout></PrivateRoute>} />



      </Routes>
    </Router>
  );
};

export default App;

