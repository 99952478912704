import React, { useEffect, useState } from "react";
import axiosInstance from '../../services/AxiosInstance';
import CustomSwitch from '../CustomSwitch';

const LoanDetailsTable = () => {
  const [loanApplications, setLoanApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  // Fetch loan applications from the API
  useEffect(() => {
    fetchLoanApplications();
  }, []);

  const fetchLoanApplications = async () => {
    try {
      const response = await axiosInstance.get("/backend/api/loans/applications");
      setLoanApplications(response.data);
    } catch (err) {
      setError("Failed to fetch loan applications");
    } finally {
      setLoading(false);
    }
  };

  // Handle status toggle
  const handleToggleStatus = async (id, currentStatus) => {

    const newStatus = currentStatus === "Pending" ? "Approved" : "Pending";

    try {
      // Update status in the backend
      await axiosInstance.put(`/backend/api/loans/applications/${id}`, { status: newStatus,  contacted: true });

      // Update status in the frontend
      setLoanApplications((prevApplications) =>
        prevApplications.map((application) =>
          application.id === id ? { ...application, status: newStatus } : application
        )
      );
    } catch (err) {
      console.error("Failed to update status", err);
      alert("Failed to update status. Please try again.");
    }
  };

  const toggleContacted = async (id, contacted) => {
    if (contacted) return; // Prevent toggling if already contacted

    try {
        await axiosInstance.put(`/backend/api/loans/applications/${id}`, { contacted: true });
        fetchLoanApplications();
    } catch (error) {
      console.error('Error toggling contacted status:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto mt-10 px-10">
      <h1 className="text-2xl font-bold mb-4">Loan Applications</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse border border-gray-200 shadow-md">
          <thead className="bg-gray-100">
            <tr>
              <th className="border border-gray-300 px-4 py-2 text-left">ID</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Name</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Email</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Phone</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Amount</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Purpose</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Loan Type</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Status(Approved/Pending)</th>
              <th className="border border-gray-300 px-4 py-2 text-left">Contacted</th>

            </tr>
          </thead>
          <tbody>
            {loanApplications.map((application) => (
              <tr key={application.id} className="hover:bg-gray-50">
                <td className="border border-gray-300 px-4 py-2">{application.id}</td>
                <td className="border border-gray-300 px-4 py-2">{application.name}</td>
                <td className="border border-gray-300 px-4 py-2">{application.email}</td>
                <td className="border border-gray-300 px-4 py-2">{application.phone}</td>
                <td className="border border-gray-300 px-4 py-2">{application.amount}</td>
                <td className="border border-gray-300 px-4 py-2">{application.purpose}</td>
                <td className="border border-gray-300 px-4 py-2">{application.loanType}</td>
                <td className="border px-4 py-2 text-center">
                                <CustomSwitch
                                  checked={application.status === "Approved"}
                                  onChange={() => handleToggleStatus(application.id, application.status)}
                                  disabled={application.status === "Approved"} // Disable switch if already contacted
                                />
                              </td>
                <td className="border px-4 py-2 text-center">
                                <CustomSwitch
                                  checked={application.contacted}
                                  onChange={() => toggleContacted(application.id, application.contacted)}
                                  disabled={application.contacted } // Disable switch if already contacted
                                />
                              </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LoanDetailsTable;
