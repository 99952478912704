import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function AboutUs() {
  const navigate = useNavigate();

  const handleCareer = async () => {
    try {
      navigate('/career');
    } catch (err) {
      console.error(err);
    } 
  };

  return (
    <div className="about-us-page">
      <section id="about" className="pt-36 pb-16 bg-gradient-to-b from-blue-50 to-white">
        <div className="container mx-auto px-4">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-bold text-gray-800 mb-6">About Parivartan Services</h1>
            <p className="text-xl text-gray-600 leading-relaxed max-w-4xl mx-auto italic">
              Dedicated to bridging the gap between aspirations and opportunities, Parivartan Services 
              is your trusted partner in career development and job placement. We empower individuals 
              to reach their full potential and connect businesses with exceptional talent.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {[
              {
                title: "Our Mission",
                description: "To empower job seekers with the skills, knowledge, and opportunities they need to thrive in their careers. We are committed to fostering growth, facilitating meaningful connections, and driving positive change in the employment landscape. Through personalized guidance and innovative solutions, we aim to transform career aspirations into tangible success stories.",
                image: "./images/ourmission.png"
              },
              {
                title: "Our Vision",
                description: "To be the catalyst for career excellence, recognized globally as the most trusted and innovative partner in professional development and job placement. We envision a world where every individual can access the resources and opportunities they need to build fulfilling careers. By continuously evolving our services and embracing cutting-edge technologies, we strive to shape the future of work and career advancement.",
                image: "./images/ourvision.png"
              },
              {
                title: "Our Values",
                description: "Integrity, Excellence, and Innovation form the cornerstone of our operations. We believe in fostering trust through transparent practices, delivering exceptional service that exceeds expectations, and continuously pushing the boundaries of what's possible in career development. Our commitment to these values ensures that we not only meet but surpass the evolving needs of both job seekers and employers in a dynamic global market.",
                image: "./images/ourvalues.png"
              }
            ].map((item, index) => (
              <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105">
                <img src={item.image} alt={item.title} className="w-full h-48 object-cover " />
                <div className="p-6">
                  <h3 className="text-2xl text-gray-800 mb-3 font-bold">{item.title}</h3>
                  <p className="text-gray-600 leading-relaxed text-justify">{item.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-20">
            <h2 className="text-4xl font-bold text-gray-800 mb-10 text-center">Our Expertise</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  title: "Strategic Job Placement",
                  description: "Leveraging advanced algorithms and industry insights to match candidates with their ideal roles, ensuring long-term success and satisfaction."
                },
                {
                  title: "Comprehensive Skill Development",
                  description: "Offering cutting-edge training programs that equip individuals with in-demand skills, enhancing their marketability and career prospects."
                },
                {
                  title: "Personalized Career Guidance",
                  description: "Providing expert advice tailored to individual aspirations, helping navigate complex career decisions with confidence."
                },
                {
                  title: "Interview Mastery Preparation",
                  description: "Equipping candidates with advanced techniques to excel in interviews, from behavioral questions to technical assessments."
                },
                {
                  title: "Strategic Resume Crafting",
                  description: "Creating compelling narratives that highlight unique strengths and achievements, tailored to catch the eye of top employers."
                },
                {
                  title: "Extensive Industry Network",
                  description: "Cultivating strong relationships with leading companies across sectors, opening doors to exclusive opportunities."
                }
              ].map((item, index) => (
                <div key={index} className="bg-gradient-to-br from-blue-50 to-white p-6 rounded-lg shadow-md hover:shadow-xl transition duration-300 border-black border-2">
                  <h4 className="text-xl font-bold text-blue-800 mb-3 italic">{item.title}</h4>
                  <p className="text-gray-700 text-justify">{item.description}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-14 bg-blue-100 pt-4">
            <h2 className="text-4xl font-bold text-gray-800 mb-10 text-center">Meet Our Team</h2>
            <p className="text-xl text-gray-600 leading-relaxed text-center max-w-4xl mx-auto mb-12 italic">
              Our diverse team of seasoned professionals brings together expertise from various industries, 
              recruitment backgrounds, and career counseling specialties. United by a passion for making a 
              positive impact, they work tirelessly to guide individuals towards their dream careers and help 
              businesses find their perfect match.
            </p>
            <div className=" rounded-xl p-2 shadow-lg">
              <img 
                src="./images/teamimage.png?height=400&width=800" 
                alt="Our Team" 
                className="rounded-lg mx-auto shadow-md"
              />
            </div>
          </div>

          <div className="mt-4 bg-gray-100 pt-10">
            <h2 className="text-4xl font-bold text-gray-800 mb-10 text-center">Our Clients</h2>
            <p className="text-xl text-gray-600 leading-relaxed text-center max-w-4xl mx-auto mb-12 italic">
              We take pride in our partnerships with industry leaders and innovative startups alike. 
              Our diverse client base trusts us to deliver exceptional talent, while job seekers rely on 
              our connections to access exciting opportunities. Together, we're writing success stories 
              that inspire and transform careers.
            </p>
            <div className="bg-gray-100 rounded-xl p-8 shadow-lg">
              <img 
                src="./images/ourclients.png" 
                alt="Our Clients" 
                className="rounded-lg mx-auto shadow-md"
              />
            </div>
          </div>

          <div className="mt-20">
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 px-8 rounded-2xl text-center shadow-2xl">
              <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Career?</h2>
              <p className="text-xl mb-8 max-w-2xl mx-auto">
                Take the first step towards your dream job. Let Parivartan Services guide you to success 
                with our expert placement services and career development programs.
              </p>
              <button
                className="inline-flex items-center px-8 py-4 bg-white text-blue-700 font-bold rounded-full hover:bg-blue-100 transition duration-300 text-lg shadow-md"
                onClick={() => handleCareer()}
              >
                Start Your Journey
                <svg
                  className="w-6 h-6 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

