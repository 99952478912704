import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import axiosInstance from '../../services/AxiosInstance';
import JobForm from '../admin/JobForm';

const DashboardCategoryCards = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [editingJob, setEditingJob] = useState(null);

  const fetchJobs = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/backend/jobs/getall');
      const sortedJobs = response.data.sort((a, b) => {
        return b.id - a.id; 
      });
      setJobs(sortedJobs);
    } catch (err) {
      setError('Failed to load job data. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleView = (category, title) => {
    navigate(`/admin/job/${category}`, { state: { category, title } });
  };

  const handleEdit = async (jobId) => {
    try {
      const response = await axiosInstance.get(`/backend/jobs/${jobId}`);
      setEditingJob(response.data);
    } catch (err) {
      console.error('Failed to fetch job data:', err);
      await Swal.fire(
        'Error!',
        'Failed to fetch job data. Please try again.',
        'error'
      );
    }
  };

  const handleDelete = async (jobId) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    });

    if (result.isConfirmed) {
      try {
        await axiosInstance.delete(`/backend/jobs/${jobId}`);
        await Swal.fire(
          'Deleted!',
          'The job has been deleted.',
          'success'
        );
        fetchJobs();
      } catch (err) {
        console.error('Failed to delete job:', err);
        await Swal.fire(
          'Error!',
          'Failed to delete job. Please try again.',
          'error'
        );
      }
    }
  };

  const handleUpdateSuccess = () => {
    setEditingJob(null);
    fetchJobs();
  };

  const styles = {
    container: {
      minHeight: '100vh',
      backgroundColor: '#f0f2f5',
      padding: '48px 16px',
    },
    content: {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      color: '#2c3e50',
      marginBottom: '48px',
    },
    error: {
      textAlign: 'center',
      color: '#e74c3c',
      marginBottom: '16px',
    },
    loading: {
      textAlign: 'center',
      color: '#7f8c8d',
    },
    cardGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '24px',
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      padding: '24px',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      border: '1px solid #e0e0e0',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    cardHover: {
      transform: 'translateY(-5px)',
      boxShadow: '0 12px 20px rgba(0, 0, 0, 0.15)',
    },
    cardTitle: {
      fontSize: '1.2rem',
      fontWeight: '600',
      color: '#34495e',
      marginBottom: '12px',
    },
    cardInfo: {
      fontSize: '0.95rem',
      color: '#7f8c8d',
      marginBottom: '8px',
    },
    cardSubtitle: {
      fontSize: '1rem',
      color: '#95a5a6',
      fontStyle: 'italic',
      marginBottom: '20px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 'auto',
    },
    button: {
      padding: '8px 8px',
      borderRadius: '100px',
      border: 'none',
      marginLeft: '10px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease, transform 0.1s ease',
      fontSize: '1rem',
      
    },
    viewButton: {
      backgroundColor: '#3498db',
      color: 'white',
    },
    editButton: {
      backgroundColor: '#2ecc71',
      color: 'white',
    },
    deleteButton: {
      backgroundColor: '#e74c3c',
      color: 'white',
    },
    buttonHover: {
      filter: 'brightness(110%)',
      transform: 'scale(1.05)',
    },
  };

  if (editingJob) {
    return <JobForm job={editingJob} onUpdateSuccess={handleUpdateSuccess} />;
  }

  return (
    <div style={styles.container} className='font-serif'>
      <div style={styles.content}>
        <h1 style={styles.title}>Job Categories</h1>

        {error && <p style={styles.error}>{error}</p>}

        {loading ? (
          <p style={styles.loading}>Loading...</p>
        ) : (
          <div style={styles.cardGrid}>
            {jobs.map((job, index) => (
              <div
                key={index}
                style={styles.card}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = styles.cardHover.transform;
                  e.currentTarget.style.boxShadow = styles.cardHover.boxShadow;
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'none';
                  e.currentTarget.style.boxShadow = styles.card.boxShadow;
                }}
              >
                <div style={{ flex: 1 }}>
                  <h2 style={styles.cardTitle}>{job.dtitle}</h2>
                  <p style={styles.cardInfo}>Company: {job.company}</p>
                  <p style={styles.cardInfo}>Category: {job.category}</p>
                  <p style={styles.cardSubtitle}>{job.createdAt}</p>
                </div>
                <div style={styles.buttonContainer}>
                  <button
                    onClick={() => handleView(job.category, job.title)}
                    style={{...styles.button, ...styles.viewButton}}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.filter = styles.buttonHover.filter;
                      e.currentTarget.style.transform = styles.buttonHover.transform;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.filter = 'none';
                      e.currentTarget.style.transform = 'none';
                    }}
                  >
                    👁️
                  </button>
                  <button
                    onClick={() => handleEdit(job.id)}
                    style={{...styles.button, ...styles.editButton}}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.filter = styles.buttonHover.filter;
                      e.currentTarget.style.transform = styles.buttonHover.transform;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.filter = 'none';
                      e.currentTarget.style.transform = 'none';
                    }}
                  >
                    ✏️
                  </button>
                  <button
                    onClick={() => handleDelete(job.id)}
                    style={{...styles.button, ...styles.deleteButton}}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.filter = styles.buttonHover.filter;
                      e.currentTarget.style.transform = styles.buttonHover.transform;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.filter = 'none';
                      e.currentTarget.style.transform = 'none';
                    }}
                  >
                    🗑️
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardCategoryCards;

