import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export default function Hero() {
  const images = ['/images/p5.jpg', '/images/p4.jpg', '/images/place2.jpg'];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  }, [images.length]);

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    const interval = setInterval(nextImage, 5000);
    return () => clearInterval(interval);
  }, [nextImage]);  

  return (
    <div className="relative w-full h-screen font-serif overflow-hidden">
      {images.map((src, index) => (
        <div
          key={src}
          className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
            index === currentImageIndex ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <img
            src={src}
            alt={`Carousel ${index + 1}`}
            className="w-full h-full object-cover transition-transform duration-10000 ease-in-out"
            style={{ 
              transform: `scale(${index === currentImageIndex ? 1.1 : 1})`,
              transition: 'transform 10s ease-in-out'
            }}
          />
        </div>
      ))}

      <div className="absolute inset-0 bg-gradient-to-b from-black/60 to-black/30" />

      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <h1 className="text-5xl md:text-7xl font-bold mb-6 drop-shadow-lg font-yearbook leading-tight">
            Connecting Talent <br /> with Opportunity
          </h1>
        </motion.div>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-xl md:text-2xl mb-8 drop-shadow-lg font-yearbook max-w-2xl"
        >
          Your trusted recruitment partner for the best job placement services.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.6 }}
          className="flex space-x-6"
        >
          <Link to="/career" 
            className="group relative inline-flex items-center overflow-hidden rounded-full bg-first-600 px-8 py-3 text-white focus:outline-none focus:ring active:bg-first-500"
          >
            <span className="absolute -end-full transition-all group-hover:end-4">
              <svg
                className="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>

            <span className="text-sm font-medium transition-all group-hover:me-4">
              Let's Get Started
            </span>
          </Link>

          <Link to="/contact"
            className="group relative inline-flex items-center overflow-hidden rounded-full border-2 border-white px-8 py-3 text-white focus:outline-none focus:ring active:text-first-500"
          >
            <span className="absolute -end-full transition-all group-hover:end-4">
              <svg
                className="h-5 w-5 rtl:rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </span>

            <span className="text-sm font-medium transition-all group-hover:me-4">
              Get In Touch
            </span>
          </Link>
        </motion.div>
      </div>

      <div className="absolute flex space-x-3 bottom-8 left-1/2 transform -translate-x-1/2">
        {images.map((_, index) => (
          <button
            key={index}
            type="button"
            className={`w-3 h-3 rounded-full transition-all duration-300 ${
              currentImageIndex === index ? 'bg-white scale-125' : 'bg-white/50 hover:bg-white/80'
            }`}
            onClick={() => setCurrentImageIndex(index)}
          />
        ))}
      </div>

      <button
        onClick={prevImage}
        className="absolute top-1/2 left-4 z-30 flex items-center justify-center h-12 w-12 rounded-full bg-white/30 hover:bg-white/50 transition-all duration-300 transform -translate-y-1/2 focus:outline-none"
        aria-label="Previous image"
      >
        <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
      </button>

      <button
        onClick={nextImage}
        className="absolute top-1/2 right-4 z-30 flex items-center justify-center h-12 w-12 rounded-full bg-white/30 hover:bg-white/50 transition-all duration-300 transform -translate-y-1/2 focus:outline-none"
        aria-label="Next image"
      >
        <svg className="w-6 h-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
      </button>
    </div>
  );
}

