import React, { useState } from 'react';
import { Briefcase, Users, BookOpen, BarChart, Award, Headphones, CheckCircle, ChevronLeft, ChevronRight, UserCircle, Code } from 'lucide-react';
import ReadyToTransform from '../components/parivartanHome/ReadyToTransform';
import './ServicePage.css';

const services = [
  {
    title: "Job Placement",
    description: "Our Job Placement service is the cornerstone of our offerings, designed to bridge the gap between talented professionals and leading employers. We understand that finding the right job is about more than just matching skills to requirements; it's about aligning career goals, company culture, and personal aspirations. Our team of experienced recruiters leverages an extensive network of industry connections and cutting-edge matching algorithms to ensure we find the perfect fit for both candidates and employers. Whether you're a recent graduate looking to kickstart your career or a seasoned professional seeking new challenges, our personalized approach ensures that your unique talents and ambitions are recognized and valued.",
    icon: <Briefcase className="w-12 h-12 text-emerald-500" />,
    benefits: [
      "Access to exclusive job opportunities",
      "Personalized job matching",
      "Interview preparation and coaching",
      "Salary negotiation support"
    ]
  },
  {
    title: "Staffing Solutions",
    description: "Our Staffing Solutions service is tailored to meet the dynamic workforce needs of businesses across various industries. We understand that flexibility and scalability are crucial in today's fast-paced business environment. Our comprehensive staffing approach covers temporary, contract, and permanent placements, ensuring that your organization has access to top-tier talent exactly when you need it. We pride ourselves on our ability to quickly understand your unique business requirements and company culture, allowing us to provide candidates who not only possess the right skills but also fit seamlessly into your team. From short-term projects to long-term strategic hires, our staffing solutions are designed to enhance your workforce efficiency and drive your business forward.",
    icon: <Users className="w-12 h-12 text-emerald-500" />,
    benefits: [
      "Flexible workforce solutions",
      "Reduced time-to-hire",
      "Access to pre-screened candidates",
      "Scalable staffing options"
    ]
  },
  {
    title: "Career Guidance",
    description: "Our Career Guidance service is designed to empower professionals at all stages of their careers to make informed decisions and achieve their full potential. We offer expert career counseling, leveraging our deep understanding of industry trends and job market dynamics. Our experienced career coaches work one-on-one with clients to explore their strengths, passions, and goals, helping them chart a clear path to success. From recent graduates navigating their first job search to seasoned professionals considering a career change, we provide tailored advice, resume optimization, and interview coaching to ensure our clients stand out in competitive job markets and make meaningful progress in their careers.",
    icon: <BookOpen className="w-12 h-12 text-emerald-500" />,
    benefits: [
      "Personalized career path planning",
      "Industry insights and trend analysis",
      "Resume and LinkedIn profile optimization",
      "Mock interviews and feedback"
    ]
  },
  {
    title: "Skills Assessment",
    description: "Our Skills Assessment service offers a comprehensive evaluation of candidates' abilities, ensuring they meet industry standards and employer requirements. We utilize cutting-edge assessment tools and methodologies to provide an objective analysis of technical skills, soft skills, and cultural fit. Our assessments go beyond surface-level qualifications, delving into practical application of knowledge and problem-solving abilities. This service is invaluable for both employers looking to make informed hiring decisions and individuals seeking to understand their strengths and areas for improvement. By providing detailed, data-driven insights, we help bridge the gap between perceived and actual skills, facilitating better job matches and targeted professional development.",
    icon: <BarChart className="w-12 h-12 text-emerald-500" />,
    benefits: [
      "Objective skill evaluation",
      "Identification of strengths and areas for improvement",
      "Customized assessment tools",
      "Detailed reporting and analysis"
    ]
  },
  {
    title: "Professional Development",
    description: "Our Professional Development service is dedicated to fostering continuous growth and learning in the workplace. We design and deliver tailored training programs that keep employees' skills sharp and up-to-date with the latest industry trends. Our approach combines cutting-edge learning technologies with proven pedagogical methods to create engaging, effective learning experiences. From leadership development for executives to technical skill enhancement for team members, our programs are customized to meet the specific needs of each organization and individual. By investing in your employees' growth, you not only improve their capabilities but also boost motivation, retention, and overall organizational performance.",
    icon: <Award className="w-12 h-12 text-emerald-500" />,
    benefits: [
      "Customized learning paths",
      "Skill enhancement and career growth",
      "Improved employee retention",
      "Increased productivity and innovation"
    ]
  },
  {
    title: "HR Consulting",
    description: "Our HR Consulting service provides strategic solutions to help businesses optimize their human resources processes and improve overall efficiency. We understand that effective HR management is crucial for organizational success, and our team of experienced consultants works closely with clients to develop and implement tailored strategies. From streamlining recruitment processes and developing employee engagement programs to ensuring compliance with labor laws and regulations, we offer comprehensive support across all aspects of HR. Our data-driven approach and industry best practices enable us to identify areas for improvement and implement solutions that drive tangible results, fostering a positive work environment and supporting your company's long-term growth objectives.",
    icon: <Headphones className="w-12 h-12 text-emerald-500" />,
    benefits: [
      "Streamlined HR processes",
      "Compliance with labor laws and regulations",
      "Improved employee engagement",
      "Strategic workforce planning"
    ]
  }
];

const unifiedProcess = [
  {
    title: "Initial Consultation",
    description: "We begin with a comprehensive discussion to understand your specific needs, goals, and challenges. This in-depth consultation allows us to create a tailored strategy that aligns with your objectives."
  },
  {
    title: "Custom Strategy Development",
    description: "Based on the insights gathered, our team develops a customized plan of action. We leverage our industry expertise and cutting-edge methodologies to create a strategy that addresses your unique requirements."
  },
  {
    title: "Implementation",
    description: "We put our plan into action, working closely with you at every step. Our experienced professionals ensure smooth execution, adapting as necessary to achieve optimal results."
  },
  {
    title: "Monitoring and Optimization",
    description: "We continuously monitor progress and gather feedback, making data-driven adjustments to optimize outcomes. This iterative approach ensures we deliver maximum value and meet your evolving needs."
  },
  {
    title: "Ongoing Support",
    description: "Our commitment extends beyond initial implementation. We provide ongoing support, regular check-ins, and additional resources to ensure long-term success and satisfaction with our services."
  }
];

const placementservices = [
  {
    icon: <UserCircle className="w-8 h-8" />,
    title: "Executive search and C-suite placements",
    description: "Providing top-tier talent for executive and leadership roles to drive business success."
  },
  {
    icon: <Code className="w-8 h-8" />,
    title: "IT and tech talent acquisition",
    description: "Connecting skilled IT professionals with innovative organizations."
  },
  {
    icon: <Briefcase className="w-8 h-8" />,
    title: "Temporary and contract staffing solutions",
    description: "Flexible staffing to meet short-term and project-based workforce needs."
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: "Career transition and outplacement services",
    description: "Supporting employees through career transitions with professional guidance."
  },
  {
    icon: <BarChart className="w-8 h-8" />,
    title: "HR consulting and workforce optimization",
    description: "Optimizing workforce strategies to maximize efficiency and growth."
  }
];

export default function DetailedServices() {
  const [currentProcessIndex, setCurrentProcessIndex] = useState(0);

  const nextProcess = () => {
    setCurrentProcessIndex((prevIndex) => (prevIndex + 1) % unifiedProcess.length);
  };

  const prevProcess = () => {
    setCurrentProcessIndex((prevIndex) => (prevIndex - 1 + unifiedProcess.length) % unifiedProcess.length);
  };

  return (
    <div className="mb-10 pt-24 min-h-screen bg-gradient-to-br from-gray-100 to-gray-50  text-black font-serif">
      
        <div className="relative w-full overflow-hidden ">
          <div className="flex flex-col lg:flex-row items-stretch">
            {/* Left Section */}
            <div className="w-full lg:w-[45%] bg-gray-700 p-12 relative z-10">
              <h1 className="text-5xl font-bold text-white tracking-tight mb-12">
                PLACEMENT
                <br />
                SERVICES
              </h1>
              
              <div className="space-y-6">
                {placementservices.map((service, index) => (
                  <div key={index} className="flex items-start group">
                    {/* Icon Circle */}
                    <div className="flex-shrink-0 w-16 h-16 bg-emerald-600 rounded-full flex items-center justify-center text-white">
                      {service.icon}
                    </div>
                    
                    {/* Content with Arrow Shape */}
                    <div className="relative ml-4 flex-grow">
                      <div 
                        className="bg-emerald-600 p-4"
                        style={{
                          clipPath: 'polygon(0 0, calc(100% - 20px) 0, 100% 50%, calc(100% - 20px) 100%, 0 100%)'
                        }}
                      >
                        <h3 className="text-lg font-semibold text-white mb-1">
                          {service.title}
                        </h3>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Right Section - Image with tilt */}
            <div className="w-full lg:w-[55%] relative overflow-hidden">
              <div className="absolute inset-0 bg-gray-700 transform -skew-x-6 origin-top-left"></div>
              <div className="relative z-10 w-full h-full transform skew-x-6 origin-top-left">
                <img
                  src="./images/placementserviceright.png"
                  alt="Placement Service Quality Assurance"
                  className="w-full h-full object-cover object-center"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="max-w-7xl mx-auto">
            <div className="text-center my-12">
              <h2 className="text-4xl font-bold mb-4 ">Our Services</h2>
              <p className="text-xl text-gray-900 max-w-3xl mx-auto">
                Discover our comprehensive range of professional services designed to empower your career and business growth.
              </p>
            </div>

            {services.map((service, index) => (
              <div key={index} className="mb-16 bg-slate-300 rounded-xl mx-8">
                <div className="bg-white/10 backdrop-blur-sm rounded-lg p-10 transform transition-transform hover:scale-105 mb-8">
                  <div className="flex items-center justify-center mb-6">
                    <div className="bg-emerald-800 rounded-full p-4">
                      {React.cloneElement(service.icon, { className: "w-12 h-12 text-white" })}
                    </div>
                  </div>
                  <h2 className="text-2xl font-bold mb-4 text-center">{service.title}</h2>
                  <p className="text-gray-800 mb-6 text-justify italic">{service.description}</p>
                  
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-xl font-semibold mb-4 text-center">Key Benefits</h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 justify-self-center">
                        {service.benefits.map((benefit, i) => (
                          <div key={i} className="flex items-center">
                            <CheckCircle className="w-5 h-5 text-blue-600 mr-2 flex-shrink-0 mt-1" />
                            <span className="text-gray-800">{benefit}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mt-16 pb-4">
              <h2 className="text-4xl font-bold mb-8 text-center">Our Process</h2>
              <div className="relative">
                <div className="flex justify-between items-center">
                  <button onClick={prevProcess} className="bg-emerald-600 p-2 rounded-full">
                    <ChevronLeft className="w-6 h-6" />
                  </button>
                  <div className="overflow-hidden w-4/5">
                    <div className="flex transition-transform duration-300 ease-in-out " style={{ transform: `translateX(-${currentProcessIndex * 100}%)` }}>
                      {unifiedProcess.map((step, index) => (
                        <div key={index} className="w-full flex-shrink-0 px-4 bg-gray-300 rounded-full">
                          <div className=" rounded-lg p-6 h-full">
                            <div className="bg-emerald-600 rounded-full w-12 h-12 flex items-center justify-center mb-4 mx-auto">
                              <span className="text-white font-bold text-lg">{index + 1}</span>
                            </div>
                            <h4 className="text-xl font-semibold mb-2 text-center">{step.title}</h4>
                            <p className="text-gray-800 text-center">{step.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <button onClick={nextProcess} className="bg-emerald-600 p-2 rounded-full">
                    <ChevronRight className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </div>
          </div>

        <ReadyToTransform/>
    </div>
  );
}

