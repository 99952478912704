import React, { useState } from 'react';
import { FaPercent, FaCalendarAlt, FaChartLine, FaShieldAlt } from 'react-icons/fa';
import Swal from 'sweetalert2';
import axiosInstance from '../../services/AxiosInstance';

const LoanCard = ({ title, description, interestRate, term, onApply, icon }) => (
  <div className="bg-white rounded-xl shadow-lg p-8 transition-all duration-300 hover:shadow-2xl transform hover:-translate-y-2">
    <div className="flex items-center mb-6">
      <div className="bg-blue-100 p-3 rounded-full mr-4">
        {icon}
      </div>
      <h2 className="text-3xl font-bold text-gray-800">{title}</h2>
    </div>
    <p className="text-gray-600 mb-6 text-justify">{description}</p>
    <div className="flex justify-between items-center mb-6">
      <div>
        <span className="text-sm text-gray-500 block">Interest Rate</span>
        <span className="text-2xl font-bold text-green-800">{interestRate}%</span>
      </div>
      <div>
        <span className="text-sm text-gray-500 block">Loan Term</span>
        <span className="text-2xl font-bold text-green-800">{term}</span>
      </div>
    </div>
    <button
      onClick={onApply}
      className="w-full bg-blue-600 text-white font-bold py-3 px-6 rounded-lg hover:bg-blue-700 transition duration-300 transform hover:scale-105"
    >
      Apply Now
    </button>
  </div>
);

const LoanForm = ({ loanType, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    amount: '',
    purpose: '',
    loanType: loanType,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        // Send the POST request with form data
        const response = await axiosInstance.post('/backend/api/loans/apply', formData);
  
        if (response.status === 200) {
          Swal.fire({
            title: 'Application Successful!',
            text: 'Your loan application has been submitted.',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          setFormData({ name: '', email: '', loanAmount: '' }); // Reset form
        }
      } catch (error) {
        Swal.fire({
          title: 'Application Failed',
          text: 'Something went wrong. Please try again.',
          icon: 'error',
          confirmButtonText: 'OK',
        });
        console.error('Error submitting loan application:', error);
      }
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-2xl p-8 max-w-md w-full  h-full overflow-scroll">
        <h2 className="text-3xl font-bold mb-6 text-gray-800">{loanType} Loan Application</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-gray-700 font-semibold mb-2">
              Phone Number
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
              required
            />
          </div>
          <div>
            <label htmlFor="amount" className="block text-gray-700 font-semibold mb-2">
              Loan Amount
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
              required
            />
          </div>
          <div>
            <label htmlFor="purpose" className="block text-gray-700 font-semibold mb-2">
              Loan Purpose
            </label>
            <textarea
              id="purpose"
              name="purpose"
              value={formData.purpose}
              onChange={handleChange}
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
              required
              rows="4"
            ></textarea>
          </div>
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-6 py-3 text-gray-600 hover:text-gray-800 font-semibold transition duration-200"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-300 transform hover:scale-105"
            >
              Submit Application
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const EnhancedLoanPage = () => {
  const [activeLoanForm, setActiveLoanForm] = useState(null);

  const openLoanForm = (loanType) => {
    setActiveLoanForm(loanType);
  };

  const closeLoanForm = () => {
    setActiveLoanForm(null);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-16 pt-36 px-4 sm:px-6 lg:px-8 font-serif">
      <div className="max-w-7xl mx-auto">
        <header className="text-center mb-16">
          <h1 className="text-5xl font-extrabold text-gray-900 mb-4">Discover Your Ideal Loan</h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Choose between our government-backed Mahamandal Loan or flexible Private Loan options to meet your financial needs.
          </p>
        </header>
        <div className="grid md:grid-cols-2 gap-12">
          <LoanCard
            title="Mahamandal Loan"
            description="Government-backed financial assistance designed to support various sectors of society, focusing on empowering marginalized communities and promoting economic development. Enjoy favorable terms and lower interest rates compared to private loans."
            interestRate={4.5}
            term="Up to 10 years"
            onApply={() => openLoanForm('Mahamandal')}
            icon={<FaShieldAlt className="text-blue-600 text-3xl" />}
          />
          <LoanCard
            title="Private Loan"
            description="Flexible financial products offered by banks, credit unions, and other private lenders. Benefit from more flexible eligibility criteria and faster processing times. Ideal for those seeking quick access to funds for various purposes."
            interestRate={8.75}
            term="1-7 years"
            onApply={() => openLoanForm('Private')}
            icon={<FaChartLine className="text-blue-600 text-3xl" />}
          />
        </div>
        {activeLoanForm && (
          <LoanForm loanType={activeLoanForm} onClose={closeLoanForm} />
        )}
        <section className="mt-24">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-800">Why Choose Our Loans?</h2>
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-md text-center">
              <FaPercent className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Competitive Rates</h3>
              <p className="text-gray-600">We offer some of the most competitive interest rates in the market.</p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-md text-center">
              <FaCalendarAlt className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Flexible Terms</h3>
              <p className="text-gray-600">Choose from a variety of repayment terms that suit your financial situation.</p>
            </div>
            <div className="bg-white p-8 rounded-xl shadow-md text-center">
              <FaShieldAlt className="text-blue-600 text-4xl mx-auto mb-4" />
              <h3 className="text-xl font-semibold mb-2">Secure Process</h3>
              <p className="text-gray-600">Your information is protected with state-of-the-art security measures.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default EnhancedLoanPage;

