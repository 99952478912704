import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Home() {

    const navigate = useNavigate();
  
    const handleCareer = async () => {
      try {
        navigate('/career');
      } catch (err) {
        console.error(err);
      } 
    };

  return (
    <div>
      <div className="my-10 mx-5">
            <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16 px-8 rounded-2xl text-center shadow-2xl">
              <h2 className="text-4xl font-bold mb-6">Ready to Transform Your Career?</h2>
              <p className="text-xl mb-8 max-w-2xl mx-auto">
                Take the first step towards your dream job. Let Parivartan Services guide you to success 
                with our expert placement services and career development programs.
              </p>
              <button
                className="inline-flex items-center px-8 py-4 bg-white text-blue-700 font-bold rounded-full hover:bg-blue-100 transition duration-300 text-lg shadow-md"
                onClick={() => handleCareer()}
              >
                Start Your Journey
                <svg
                  className="w-6 h-6 ml-2"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </div>
          </div>

    </div>
  );
}
