import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'; 
import axiosInstance from '../../services/AxiosInstance';

export default function QuestionIcon() {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialFormData = {
    message: '',
    mobilenumber: '',
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(`/backend/askquestion`, formData);

      if (response.status === 200) {
        setFormData(initialFormData); // Reset form data on successful submission
        setModalOpen(false); // Close modal on success

        // Show SweetAlert success message
        Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'We will get back to you with Answer Shortly.',
          confirmButtonColor: '#3085d6',
          timer: 3000,
        });
      } else {
        console.error('Failed to submit form');
      }
    } catch (error) {
      console.error('Error occurred:', error);

      // Optional: Show an error alert
      Swal.fire({
        icon: 'error',
        title: 'Oops!',
        text: 'Something went wrong. Please try again.',
        confirmButtonColor: '#d33',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className="fixed bottom-4 right-4 z-30">
      <button
        onClick={handleClick}
        className="bg-first-600 text-white p-3 rounded-full shadow-lg hover:bg-first-700 transition duration-300 border-2"
      >
        <FontAwesomeIcon icon={faMessage} size="lg" />
      </button>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center px-auto py-auto"
          onClick={handleClose}
        >
          <div
            className="bg-white rounded-lg p-6 w-96 sm:w-2/3 md:w-1/2 lg:w-1/3"
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
          >
            <h2 className="text-xl font-bold mb-4 text-center">Ask Me a Question</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="number"
                name="mobilenumber"
                className="w-full p-2 border border-gray-300 rounded-lg mb-4"
                placeholder="Enter your Mobile Number here..."
                value={formData.mobilenumber}
                onChange={handleInputChange}
                required
              />
              <textarea
                name="message"
                className="w-full h-24 border border-gray-300 rounded-lg p-2 mb-4"
                placeholder="Enter your message here..."
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
              <div className="flex justify-end">
                <button
                  type="button"
                  className="mr-2 bg-gray-300 text-gray-700 px-4 py-2 rounded sm:text-xs"
                  onClick={handleClose}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`bg-first-600 text-white px-4 py-2 rounded hover:bg-first-700 transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
