import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import JobCard from './JobCard';
import axiosInstance from '../../services/AxiosInstance';
import '../career/Career.css';

export default function JobList() {
  const [jobs, setJobs] = useState([]); // State to store fetched jobs
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading

  // Function to open the modal with the selected job
  const openModal = (job) => {
    setSelectedJob(job);
    console.log('Modal opened for job:', job); // Debugging log to check if modal is opened
  };

  // Fetch job data from API
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axiosInstance.get('/backend/jobs/getall');
        console.log(response);
        const sortedJobs = response.data.sort((a, b) => {
          return b.id - a.id;  // Sort by `id` in descending order
        });
        setJobs(sortedJobs);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchJobs();
  }, []);

  // Get the first 3 recommended jobs
  const recommendedJobs = jobs.filter((job) => job.recommended).slice(0, 3);

  // Get the first 3 other jobs
  const otherJobs = jobs.filter((job) => !job.recommended).slice(0, 3);

  // Combine both arrays (optional, depending on your needs)
  const displayedJobs = [...recommendedJobs, ...otherJobs];

  return (
    <div className="container mx-auto p-4 bg-slate-300">
      <h1 className="text-4xl font-bold text-center mb-8 mt-8 font-serif italic">
        Latest Job Openings
      </h1>

      {/* Display loading spinner and message while fetching jobs */}
      {loading ? (
        <div className="flex justify-center items-center">
          <div className="spinner mr-2"></div>
          <p className="text-gray-600">Loading New Jobs for you...</p>
        </div>
      ) : (
        // Display jobs after loading
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
          {displayedJobs.map((job, index) => (
            <JobCard key={index} job={job} openModal={openModal} />
          ))}
        </div>
      )}

      {/* View More button with hover effect */}
      <div className="text-center mt-16 mb-6">
        <Link
          to="/career"
          className="bg-blue-700 text-white text-xl py-3 px-6 rounded-xl hover:bg-blue-900 transition-all duration-300 hover:py-4 hover:px-8 font-serif"
        >
          View More Jobs
        </Link>
      </div>

      {/* Modal rendering logic */}
      {selectedJob && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center overflow-scroll top-24  z-20 ">
          <div className="bg-white rounded-lg p-4 w-full sm:p-8 max-w-full sm:max-w-2xl mx-auto absolute top-10">
            <div className="flex justify-between items-center mb-4 mt-10">
              <h3 className="text-2xl font-bold font-yearbook">
                {selectedJob.dtitle}
              </h3>
              <button
                onClick={() => setSelectedJob(null)}
                className="text-2xl text-rose-600"
              >
                &times;
              </button>
            </div>
            <hr className="border-t border-blue-500 my-4" />

            <div className="mb-4">
              <strong className="font-yearbook">Profile Required:</strong>
              <p className="text-left mb-2 font-yearbook">{selectedJob.profile}</p>
            </div>

            <div className="mb-4">
              <strong className="font-yearbook">Skills Required:</strong>
              <ul className="list-disc pl-5 mt-2">
                {selectedJob.skills.map((skill, index) => (
                  <li key={index} className="text-left mb-2 font-yearbook">
                    {skill}
                  </li>
                ))}
              </ul>
            </div>

            <hr className="border-t border-blue-500 my-4" />

            <div className="flex justify-center">
              <Link
                to={`/apply/${encodeURIComponent(selectedJob.category)}/${encodeURIComponent(selectedJob.title)}`}
                className="bg-first-500 text-white py-2 px-4 rounded-lg hover:bg-first-700 transition duration-300 ease-in-out font-yearbook"
              >
                Apply
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
