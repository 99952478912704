import React, { useState } from 'react';
import axiosInstance from '../../services/AxiosInstance';
import { Send } from 'lucide-react';
import Swal from 'sweetalert2';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobilenumber: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axiosInstance.post('/backend/contact', formData);
      console.log(response);
      setFormData({ name: '', mobilenumber: '', email: '', message: '' });
      
      // Show success SweetAlert
      Swal.fire({
        title: 'Success!',
        text: 'Your message has been sent successfully.',
        icon: 'success',
        confirmButtonText: 'OK',
      });
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      
      // Show error SweetAlert
      Swal.fire({
        title: 'Oops!',
        text: 'There was an error sending your message. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="bg-gradient-to-b from-gray-50 to-white min-h-screen py-20 mt-20 px-5 font-serif">
      <div className="container mx-auto px-4">
        <h1 className="text-5xl font-bold text-center text-gray-800 mb-4 font-yearbook">Get In Touch</h1>
        <p className="text-xl text-center text-gray-600 mb-12 max-w-3xl mx-auto font-yearbook">
          We'd love to hear from you! Reach out to learn more about our work or how you can get involved.
        </p>

        <div className="flex flex-col lg:flex-row items-stretch">
          <div className="w-full lg:w-1/2">
            <form onSubmit={handleSubmit} className="bg-white p-8 shadow-2xl rounded-2xl h-full flex flex-col">
              <div className="grid grid-cols-1 gap-6 mb-6 flex-grow">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-first-600 transition-shadow font-yearbook"
                  required
                />
                <input
                  type="tel"
                  name="mobilenumber"
                  value={formData.mobilenumber}
                  onChange={handleChange}
                  placeholder="Your Mobile Number"
                  className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-first-600 transition-shadow font-yearbook"
                  required
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-first-600 transition-shadow font-yearbook"
                  required
                />
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  className="p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-first-600 transition-shadow font-yearbook flex-grow"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-first-600 text-white px-6 py-3 rounded-lg hover:bg-green-800 transition-colors shadow-lg hover:shadow-xl focus:ring-2 focus:ring-blue-300 focus:outline-none font-yearbook flex items-center justify-center"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Sending...' : 'Send Message'}
                <Send className="ml-2 h-5 w-5" />
              </button>
            </form>
          </div>

          <div className="w-full lg:w-1/2">
            <div className=" h-full">
              <img 
                src="./images/contactus.png?height=600&width=500" 
                alt="Contact Us" 
                className="w-full h-full object-cover "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
