import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  FiHome, FiUser, FiLogOut, FiAirplay, FiChevronLeft, 
  FiChevronRight, FiBriefcase, FiMail, FiHelpCircle, FiCheckCircle 
} from 'react-icons/fi';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import './Sidebar.css';
import {isLoggedIn} from "../../auth";

const menuItems = [
  { icon: FiHome, label: 'Categories', href: '/admin/dashboard' },
  { icon: FiUser, label: 'All Candidates', href: '/admin/allcandidates' },
  { icon: FiAirplay, label: 'Add MCQ', href: '/admin/add_mcq' },
  { icon: FiCheckCircle, label: 'Show MCQ', href: '/admin/show-mcq' },
  { icon: FiBriefcase, label: 'Job Creation', href: '/admin/add_job' },
  { icon: FiMail, label: 'Contact Inquiries', href: '/admin/contactdetails' },
  { icon: FiHelpCircle, label: 'Asked Questions', href: '/admin/askquedetails' },
  { icon: FaMoneyCheckAlt, label: 'Loan Applications', href: '/admin/loanapplications' }

];

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();

  const handleLogout = () => {
        localStorage.removeItem("token");
        navigate('/login', { replace: true });
  };

  useEffect(() => {
    if (!isLoggedIn()) {
        navigate("/login");
    }
}, [navigate])

  return (
    <aside className={`font-serif sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        <div className="logo-container">
          <img src="/images/logo.jpg" alt="Parivartan Service Logo" className="logo" />
          {isOpen && <h2 className="logo-text">Parivartan Services</h2>}
        </div>
        <button className="toggle-btn" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <FiChevronLeft /> : <FiChevronRight />}
        </button>
      </div>

      <nav className="sidebar-nav">
        {menuItems.map((item) => (
          <Link
            key={item.href}
            to={item.href}
            className="nav-item"
            title={!isOpen ? item.label : ''}
          >
            <item.icon className="nav-icon" />
            {isOpen && <span className="nav-label">{item.label}</span>}
          </Link>
        ))}
        {/* Add Logout Button */}
        <div 
          className="nav-item logout-item" 
          title={!isOpen ? 'Logout' : ''} 
          onClick={handleLogout}
        >
          <FiLogOut className="nav-icon" />
          {isOpen && <span className="nav-label">Logout</span>}
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
