import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isActive = (path) => location.pathname === path;

  return (
    <nav className="bg-gray-50 fixed w-full z-20 top-0 start-0 border-b-2 border-black font-serif">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        {/* Logo and title */}
        <Link to="/" className="flex items-center space-x-3" onClick={closeMenu}>
          <div className="flex items-center">
            <img
              src="./images/parivartan-transparant-logo.png"
              className="h-16 w-20 object-contain"
              alt="Logo"
            />
          </div>
          <span className="self-center text-2xl md:text-3xl font-bold whitespace-nowrap text-black" style={{fontFamily: 'Oleo Script'}}>
            Parivartan Services
          </span>
        </Link>
        
        {/* Mobile Menu Button */}
        <button
          type="button"
          onClick={toggleMenu}
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
          aria-controls="navbar-sticky"
          aria-expanded={isOpen}
        >
          <span className="sr-only">Toggle main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>

        {/* Navigation Links and Buttons */}
        <div
          className={`items-center justify-end w-full md:flex md:w-auto md:order-1 ${
            isOpen ? 'block' : 'hidden'
          }`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent">
            <li>
              <Link
                to="/"
                className={`block py-2 px-3 rounded md:p-0 ${
                  isActive('/')
                    ? 'text-first-500 font-extrabold'
                    : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700 font-bold'
                }`}
                onClick={closeMenu}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={`block py-2 px-3 rounded md:p-0 ${
                  isActive('/about')
                    ? 'text-first-500 font-extrabold'
                    : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700 font-bold'
                }`}
                onClick={closeMenu}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className={`block py-2 px-3 rounded md:p-0 ${
                  isActive('/services')
                    ? 'text-first-500 font-extrabold'
                    : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700 font-bold'
                }`}
                onClick={closeMenu}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className={`block py-2 px-3 rounded md:p-0 ${
                  isActive('/contact')
                    ? 'text-first-500 font-extrabold'
                    : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700 font-bold'
                }`}
                onClick={closeMenu}
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                to="/loan"
                className={`block py-2 px-3 rounded md:p-0 ${
                  isActive('/loan')
                    ? 'text-first-500 font-extrabold'
                    : 'text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-first-700 font-bold'
                }`}
                onClick={closeMenu}
              >
                Loan
              </Link>
            </li>
          </ul>
          
          {/* Career and Login Buttons */}
          <div className="flex flex-col md:flex-row mt-4 md:mt-0 space-y-2 md:space-y-0 md:space-x-3 ml-4">
            <Link to="/career" className="w-full md:w-auto" onClick={closeMenu}>
              <button
                type="button"
                className="w-full md:w-auto text-white bg-first-500 hover:bg-first-600 font-bold rounded-xl text-sm px-4 py-2 text-center transition-colors duration-300"
              >
                Career
              </button>
            </Link>
            <Link to="/login" className="w-full md:w-auto" onClick={closeMenu}>
              <button
                type="button"
                className="w-full md:w-auto text-white bg-first-600 hover:bg-first-500 font-bold rounded-xl text-sm px-4 py-2 text-center transition-colors duration-300"
              >
                Login
              </button>
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}